
import CmsCardComponent from '@/commoncomponents/CmsCardComponent/CmsCardComponent.vue';
import BreadcrumbComponent from '@/commoncomponents/breadcrumbComponent/BreadcrumbComponent.vue';
import MobileScreenUnavailable from '@/commoncomponents/mobileScreenUnavailable/MobileScreenUnavailable.vue';
import DeviceWidthCheckerMixin from '@/mixins/deviceWidthChecker.mixin';
import { Component, Mixins } from 'vue-property-decorator';
import * as DummyCardImage from '../../assets/images/cms/dummy_card_image.png';
import CmsAccordion from '@/commoncomponents/CmsAccordion/CmsAccordion.vue';
import CmsCardCarousel from '@/commoncomponents/CmsCardCarousel/CmsCardCarousel.vue';
import CmsLabelComponent from '@/commoncomponents/CmsLabelComponent/CmsLabelComponent.vue';
import {
  LabelData,
  exampleLabelData
} from '@/commoncomponents/CmsLabelComponent/CmsLabelComponent';
import CmsTagComponent from '@/commoncomponents/CmsTagComponent/CmsTagComponent.vue';
import { ContentCardData, CourseStatus, ContentType } from '@/utilities/cmsUtilities';

@Component({
  components: {
    'bread-crumb': BreadcrumbComponent,
    'mobile-unavailable-screen': MobileScreenUnavailable,
    'cms-card': CmsCardComponent,
    'collapsible-layout-card': CmsAccordion,
    'cms-tag-list': CmsTagComponent,
    'cms-content-label': CmsLabelComponent,
    'card-carousel': CmsCardCarousel
  }
})
export default class LearningResourcesHome extends Mixins(
  DeviceWidthCheckerMixin
) {
  cardDataArr: Array<ContentCardData> = [
    {
      title: 'Full Card Data',
      imageUrl: DummyCardImage,
      description:
        'Text here about the course. After about two maybe three lines we three lines we can go on and on and on and on',
      timeDuration: '30min - 1hr 20min',
      courseStatus: CourseStatus.IN_PROGRESS,
      contentType: ContentType.COURSE
    },
    {
      title: 'No Content Type',
      imageUrl: DummyCardImage,
      description:
        'Text here about the course. After about two maybe three lines we three lines we can go on and on and on and on',
      timeDuration: '30min - 1hr 20min',
      courseStatus: CourseStatus.COMPLETE
    },
    {
      title: 'No Time',
      imageUrl: DummyCardImage,
      description:
        'Text here about the course. After about two maybe three lines we three lines we can go on and on and on and on',
      courseStatus: CourseStatus.COMPLETE
    },
    {
      title: 'No Description',
      imageUrl: DummyCardImage,
      courseStatus: CourseStatus.TO_DO,
      timeDuration: '30min - 1hr 20min',
      contentType: ContentType.ACTIVITY
    },
    {
      title: 'No Course Status',
      imageUrl: DummyCardImage,
      description: 'Only a few words here.',
      timeDuration: '30min - 1hr 20min',
      contentType: ContentType.COURSE
    },
    { title: 'No Info' }
  ];

  cardCarouselExampleData: Array<ContentCardData> = [
    {
      title: 'Full Card Data',
      imageUrl: DummyCardImage,
      description:
        'Text here about the course. After about two maybe three lines we three lines we can go on and on and on and on',
      timeDuration: '30min - 1hr 20min',
      courseStatus: CourseStatus.IN_PROGRESS,
      contentType: ContentType.COURSE
    },
    {
      title: 'No Content Type',
      imageUrl: DummyCardImage,
      description:
        'Text here about the course. After about two maybe three lines we three lines we can go on and on and on and on',
      timeDuration: '30min - 1hr 20min',
      courseStatus: CourseStatus.COMPLETE
    },
    {
      title: '1',
      imageUrl: DummyCardImage,
      description:
        'Text here about the course. After about two maybe three lines we three lines we can go on and on and on and on',
      timeDuration: '30min - 1hr 20min',
      courseStatus: CourseStatus.COMPLETE
    },
    {
      title: '2',
      imageUrl: DummyCardImage,
      description:
        'Text here about the course. After about two maybe three lines we three lines we can go on and on and on and on',
      timeDuration: '30min - 1hr 20min',
      courseStatus: CourseStatus.COMPLETE
    },
    {
      title: '3',
      imageUrl: DummyCardImage,
      description:
        'Text here about the course. After about two maybe three lines we three lines we can go on and on and on and on',
      timeDuration: '30min - 1hr 20min',
      courseStatus: CourseStatus.COMPLETE
    },
    {
      title: '4',
      imageUrl: DummyCardImage,
      description:
        'Text here about the course. After about two maybe three lines we three lines we can go on and on and on and on',
      timeDuration: '30min - 1hr 20min',
      courseStatus: CourseStatus.COMPLETE
    },
    {
      title: '5',
      imageUrl: DummyCardImage,
      description:
        'Text here about the course. After about two maybe three lines we three lines we can go on and on and on and on',
      timeDuration: '30min - 1hr 20min',
      courseStatus: CourseStatus.COMPLETE
    },
    {
      title: '6',
      imageUrl: DummyCardImage,
      description:
        'Text here about the course. After about two maybe three lines we three lines we can go on and on and on and on',
      timeDuration: '30min - 1hr 20min',
      courseStatus: CourseStatus.COMPLETE
    },
    {
      title: '7',
      imageUrl: DummyCardImage,
      description:
        'Text here about the course. After about two maybe three lines we three lines we can go on and on and on and on',
      timeDuration: '30min - 1hr 20min',
      courseStatus: CourseStatus.COMPLETE
    },
    {
      title: '8',
      imageUrl: DummyCardImage,
      description:
        'Text here about the course. After about two maybe three lines we three lines we can go on and on and on and on',
      timeDuration: '30min - 1hr 20min',
      courseStatus: CourseStatus.COMPLETE
    },
    {
      title: '9',
      imageUrl: DummyCardImage,
      description:
        'Text here about the course. After about two maybe three lines we three lines we can go on and on and on and on',
      timeDuration: '30min - 1hr 20min',
      courseStatus: CourseStatus.COMPLETE
    },
    {
      title: '10',
      imageUrl: DummyCardImage,
      description:
        'Text here about the course. After about two maybe three lines we three lines we can go on and on and on and on',
      timeDuration: '30min - 1hr 20min',
      courseStatus: CourseStatus.COMPLETE
    },
    {
      title: '11',
      imageUrl: DummyCardImage,
      description:
        'Text here about the course. After about two maybe three lines we three lines we can go on and on and on and on',
      timeDuration: '30min - 1hr 20min',
      courseStatus: CourseStatus.COMPLETE
    },
    {
      title: '12',
      imageUrl: DummyCardImage,
      description:
        'Text here about the course. After about two maybe three lines we three lines we can go on and on and on and on',
      timeDuration: '30min - 1hr 20min',
      courseStatus: CourseStatus.COMPLETE
    },
    {
      title: 'No Time',
      imageUrl: DummyCardImage,
      description:
        'Text here about the course. After about two maybe three lines we three lines we can go on and on and on and on',
      courseStatus: CourseStatus.COMPLETE
    },
    {
      title: 'No Description',
      imageUrl: DummyCardImage,
      courseStatus: CourseStatus.TO_DO,
      timeDuration: '30min - 1hr 20min',
      contentType: ContentType.ACTIVITY
    },
    {
      title: 'No Description',
      imageUrl: DummyCardImage,
      courseStatus: CourseStatus.TO_DO,
      timeDuration: '30min - 1hr 20min',
      contentType: ContentType.ACTIVITY
    },
    {
      title: 'No Description',
      imageUrl: DummyCardImage,
      courseStatus: CourseStatus.TO_DO,
      timeDuration: '30min - 1hr 20min',
      contentType: ContentType.ACTIVITY
    },
    {
      title: 'No Course Status',
      imageUrl: DummyCardImage,
      description: 'Only a few words here.',
      timeDuration: '30min - 1hr 20min',
      contentType: ContentType.COURSE
    },
    { title: 'No Info' }
  ];

  labelData!: Array<LabelData>;

  beforeMount(): void {
    this.labelData = exampleLabelData;
  }
}
