var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-container"},[_c('div',{staticClass:"learning-resources-container"},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","flex-direction":"column"}},[_c('h2',[_vm._v("Component Examples")]),_c('h3',[_vm._v("Card Carousel")]),_c('card-carousel',{attrs:{"cardDataArr":_vm.cardCarouselExampleData,"title":"Card Carousel Title"}}),_c('h3',[_vm._v("Card Carousel With Filters Disabled")]),_c('card-carousel',{attrs:{"cardDataArr":_vm.cardCarouselExampleData,"title":"Card Carousel Title","showFilters":false}}),_c('h3',[_vm._v("Layout Card")]),_c('div',{staticClass:"collapsible-demo"},[_c('collapsible-layout-card',[_c('span',[_vm._v("Inner Content")]),_c('span',[_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore esse dignissimos expedita laudantium cum. Animi et libero incidunt, veritatis mollitia dicta voluptatibus officiis, saepe ullam natus optio aliquam eum ea! Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore esse dignissimos expedita laudantium cum. Animi et libero incidunt, veritatis mollitia dicta voluptatibus officiis, saepe ullam natus optio aliquam eum ea! Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore esse dignissimos expedita laudantium cum. Animi et libero incidunt, veritatis mollitia dicta voluptatibus officiis, saepe ullam natus optio aliquam eum ea! Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore esse dignissimos expedita laudantium cum. Animi et libero incidunt, veritatis mollitia dicta voluptatibus officiis, saepe ullam natus optio aliquam eum ea! Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore esse dignissimos expedita laudantium cum. Animi et libero incidunt, veritatis mollitia dicta voluptatibus officiis, saepe ullam natus optio aliquam eum ea! ")])]),_c('collapsible-layout-card',{attrs:{"accordionData":{
            title: 'Closed Accordion',
            description: 'This card is initially closed'
          },"isOpen":false}},[_c('span',[_vm._v("Inner Content")]),_c('span',[_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore esse dignissimos expedita laudantium cum. Animi et libero incidunt, veritatis mollitia dicta voluptatibus officiis, saepe ullam natus optio aliquam eum ea! Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore esse dignissimos expedita laudantium cum. Animi et libero incidunt, veritatis mollitia dicta voluptatibus officiis, saepe ullam natus optio aliquam eum ea! Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore esse dignissimos expedita laudantium cum. Animi et libero incidunt, veritatis mollitia dicta voluptatibus officiis, saepe ullam natus optio aliquam eum ea! Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore esse dignissimos expedita laudantium cum. Animi et libero incidunt, veritatis mollitia dicta voluptatibus officiis, saepe ullam natus optio aliquam eum ea! Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore esse dignissimos expedita laudantium cum. Animi et libero incidunt, veritatis mollitia dicta voluptatibus officiis, saepe ullam natus optio aliquam eum ea! ")])]),_c('collapsible-layout-card',{attrs:{"accordionData":{
            title: 'Large Closed Accordion',
            description: "This card has a lot of content and is initially closed m natus optio aliquam eum ea!\n            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore\n            esse dignissimos expedita laudantium cum. Animi et libero\n            incidunt, veritatis mollitia dicta voluptatibus officiis, saepe\n            ullam natus optio aliquam eum ea!"
          },"isOpen":false}},[_c('div',{staticStyle:{"height":"2000px"}},[_c('img',{attrs:{"src":"https://images.unsplash.com/photo-1682120802615-73c425d993f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1664&q=80","alt":""}}),_c('img',{attrs:{"src":"https://images.unsplash.com/photo-1681916882860-adfcc0a42d0e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2532&q=80","alt":""}}),_c('img',{attrs:{"src":"https://images.unsplash.com/photo-1682250540446-4a134ca88396?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1664&q=80","alt":""}})])]),_c('collapsible-layout-card',{attrs:{"accordionData":{
            title: 'Hidden Chevron',
            description: 'This card no longer has a chevron',
            hideChevron: true
          },"isOpen":false}},[_c('h4',[_vm._v("This content cannot be collapsed")])])],1),_c('h3',[_vm._v("Label Component")]),_c('cms-content-label',{attrs:{"labelData":_vm.labelData}}),_c('h3',[_vm._v("Label Component with only two values")]),_c('cms-content-label',{attrs:{"labelData":_vm.labelData.slice(0, 2)}}),_c('h3',[_vm._v("Tags")]),_c('cms-tag-list',{attrs:{"tagData":['Staff', 'Course', 'Activity']}}),_c('h3',[_vm._v("Card")]),_c('div',{staticClass:"cards-demo",staticStyle:{"display":"flex","flex-wrap":"wrap","gap":"20px"}},_vm._l((_vm.cardDataArr),function(data,index){return _c('div',{key:index},[_c('cms-card',{attrs:{"cardData":data}})],1)}),0),_c('p')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }